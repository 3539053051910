// ==========================================================================
// #ICONS
// ==========================================================================
.icon {
  @include bg-cover();
  display: inline-block;
  vertical-align: middle;
  background-size: contain;

  > svg {
    display: block;
  }

  path, polygon {
    transition: fill .3s;
  }
}

// ==========================================================================
// #ICONS-COLORS
// ==========================================================================
.icon--white {
  path {
    fill: white;
  }
}

.icon--black {
  path, polygon {
    fill: black !important;
  }
}

// Colors
@each $color, $shades in $palettes {
  $colorName: if($color == grey, '-', '--#{$color}');
  @each $shade, $value in $shades {
    $shadeModifier: if($shade == base, '', '-#{$shade}');

    @if $shade == base {
      $shadeModifier: if($color == grey, '-#{$color}', '');
    } @else {
      $shadeModifier: '-#{$shade}';
    }

    .icon#{$colorName}#{$shadeModifier} {
      path, polygon, rect, circle {
        fill: palette($color, $shade);
      }
    }
  }
}

// ==========================================================================
// #ICONS-SIZE
// ==========================================================================

.icon--20 {
  &,
  & > svg {
    width: 20px;
    height: 20px;
  }
}

.icon--25 {
  &,
  & > svg {
    width: 25px;
    height: 25px;
  }
}

.icon--30 {
  &,
  & > svg {
    width: 30px;
    height: 30px;
  }
}

.icon--40 {
  &,
  & > svg {
    width: 40px;
    height: 40px;
  }
}

.icon--45 {
  &,
  & > svg {
    width: 45px;
    height: 45px;
  }
}

.icon--60 {
  &,
  & > svg {
    width: 60px;
    height: 60px;
  }
}

.icon--logo {
  &,
  & > svg {
    width: 240px;
    height: auto;

    path {
      fill: #1D1D1B;
    }
  }
}


// ==========================================================================
// #ICONS-SOCIAL
// ==========================================================================
.icon--social {

  circle, path {
    transition: all .3s;
  }

  &:hover {
    svg circle {
      fill: #DA32E1;
      box-shadow: 0 0 10px 0 #DA32E1;
    }

    svg path {
      fill: white;
    }
  }
}