// ==========================================================================
// #FOOTER
// ==========================================================================
.chunk {
  position: relative;
  overflow: hidden;
}

.chunk--black-dark {
  background-color: palette(black, dark);
}

.chunk--blue {
  background-color: palette(blue);
}

.chunk--pink {
  background-color: palette(pink);
}

.chunk--gradient {
  background-image: linear-gradient(180deg, #9571EF 0%, #F251A6 99%);
}

.chunk--pale {
  background-color: #A1ADFC;
}

.chunk--pale-white-split {
  position: relative;

  .wrapper {
    position: relative;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background-color: #A1ADFC;
  }
}

.chunk--black-dark-split {
  position: relative;

  .wrapper {
    position: relative;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background-color: palette(black, dark);
  }
}

.chunk--oh {
  overflow: hidden;
}

.chunk--graffiti-purple__hold {
  position: relative;

  .ufo-dino {
    display: none;
  }

  @include breakpoint(medium) {
    .ufo-dino {
      display: block;
      position: absolute;
      left: 40%;
      top:0;
      transform: translateY(-25%) translateX(-50%);
    }
  }
}

.chunk--fomo {
  overflow: hidden;
  background-color: palette(black, dark);
  position: relative;
  padding-top: 10vh;
  padding-bottom: 10vh;

  .box {
    position: relative;
    background-color: palette(pink);
    background-image: url("/dist/img/bkg/graffiti-purple-small.jpg");
    background-position: center center;
    background-size: cover;
    color: palette(white);
    max-width: 1170px;
    padding: 32px 20px;
    margin: 0 auto;
    z-index: 2;

    @include breakpoint(small) {
      background-image: url("/dist/img/bkg/graffiti-purple.jpg");
    }

    .box__inner {
      max-width: 830px;
      margin: 0 auto;
    }

    .btn--pink-fill {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-40px) translateY(-50%) rotate(-10deg);
      pointer-events: none;

      @include breakpoint-max(medium) {
        transform: translateX(-55px) translateY(-60%) rotate(-10deg) scale(0.7);
        transform-origin: center;
      }
    }

    .fomo__decor-1 {
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(20px) translateY(-60%);
      width: 100px;
      display: none;

      @include breakpoint(medium) {
        display: block;
        width: 180px;
      }

      @include breakpoint(large) {
        width: 300px;
      }
    }

    @include breakpoint(400px) {
      padding: 32px 44px;
    }

    @include breakpoint(medium) {
      padding: 68px;

      h2 {
        font-size: 4rem;
      }
    }
  }

  @include breakpoint(medium) {
    padding-top: 13.5vh;
  }

  @include breakpoint(large) {
    padding-top: 22vh;
  }
}

.chunk--location-search {
  overflow: hidden;
  background-color: palette(teal);
  background-image: url("/dist/img/bkg/graffiti-green-small.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
  padding-top: 10vh;
  padding-bottom: 10vh;
  color: palette(white);

  @include breakpoint(small) {
    background-image: url("/dist/img/bkg/graffiti-green.jpg");
  }

  .inner {
    position: relative;

    .location-search__decor-1 {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateY(26%) translateX(15%);
      width: 300px;
      z-index: 1;

      @include breakpoint(medium) {
        transform: translateY(25%) translateX(-5%);
        width: 450px;
      }

      @include breakpoint(large) {
        width: 650px;
      }
    }

    .location-search__decor-2 {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-50%) translateX(-35%);
      width: 150px;
      z-index: 1;

      @include breakpoint(medium) {
        transform: translateY(-40%) translateX(-30%);
        width: 250px;
      }

      @include breakpoint(large) {
        transform: translateY(-40%) translateX(-10%);
        width: 386px;
      }
    }
  }

  .box {
    position: relative;
    background: palette(black, dark);
    max-width: 970px;
    padding: 32px 20px;
    margin: 0 auto;
    z-index: 2;

    @include breakpoint(400px) {
      padding: 32px 44px;
    }

    @include breakpoint(medium) {
      padding: 68px;

      h2 {
        font-size: 4rem;
      }
    }
  }

  @include breakpoint(medium) {
    padding-top: 20vh;
    padding-bottom: 20vh;
  }
}

.chunk--signup {
  overflow: hidden;
  background-color: palette(teal);
  background-image: url("/dist/img/bkg/graffiti-green-small.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
  padding-top: 10vh;
  padding-bottom: 10vh;
  color: palette(white);

  @include breakpoint(small) {
    background-image: url("/dist/img/bkg/graffiti-green.jpg");
  }

  @include breakpoint(large) {
    padding-top: 18vh;
  }

  .signup-decor__3 {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 240px;
    pointer-events: none;

    @include breakpoint(medium) {
      max-width: 550px;
    }
  }

  .signup-decor__4 {
    position: absolute;
    bottom: 80px;
    left: 0;
    max-width: 350px;
    display: none;
    z-index: 4;
    pointer-events: none;

    @include breakpoint(large) {
      display: block;
    }

    @include breakpoint(1460px) {
      max-width: 470px;
    }
  }

  .inner {
    position: relative;
    z-index: 4;
    padding: 60px 0;
  }

  .box {
    position: relative;
    background: palette(black, dark);
    max-width: 970px;
    padding: 32px 20px;
    margin: 0 auto;
    text-align: center;

    .box__inner {
      max-width: 568px;
      margin: 0 auto;
    }

    .signup-decor__1 {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(25%, -50%);
      z-index: -1;
      max-width: 340px;

      @include breakpoint(medium) {
        max-width: 650px;
      }
    }

    .signup-decor__2 {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -90%);
      z-index: 1;
      max-width: 140px;

      @include breakpoint(medium) {
        max-width: 192px;
      }
    }

    @include breakpoint(400px) {
      padding: 32px 44px;
    }
  }

  @include breakpoint(medium) {
    .inner {
      padding: 120px 0;
    }

    .box {
      padding: 68px;

      h2 {
        font-size: 4rem;
      }
    }
  }
}

.chunk--graffiti-purple {
  overflow: hidden;
  background-color: palette(pink);
  background-image: url("/dist/img/bkg/graffiti-purple-small.jpg");
  background-position: center center;
  background-size: cover;
  position: relative;
  padding-top: 15vh;
  padding-bottom: 12vh;

  .inner {
    position: relative;
    z-index: 4;
  }

  .prop {
    display: none;
  }


  @include breakpoint(medium) {
    padding-bottom: 14vh;
    background-image: url("/dist/img/bkg/graffiti-purple.jpg");

    .g {
      position: relative;
    }

    .prop {
      display: block;
      position: absolute;
    }

    .flamingo-woman {
      left: -90px;
      bottom: -50px;
    }

    .zebra {
      right:0;
      bottom: -190px;
    }
  }
}

.chunk--canvas {
  position: relative;

  canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    --gradient-color-1: #8F6DE8;
    --gradient-color-2: #72EBFE;
    --gradient-color-3: #FA9AFE;
    --gradient-color-4: #92ABFF;
  }

  .inner {
    position: relative;
    z-index: 2;
  }
}

.ufo-beam {
  position: relative;

  .beam-aliens {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(0%) translateX(-40%);
    animation: floatWeird 6s ease-in-out infinite;

    @include breakpoint(medium) {
      width: 90%;
    }
  }
}

.status-gradient-box {
  position: relative;
  height: 300px;
  margin-top: 40px;

  canvas {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    --gradient-color-1: #8F6DE8;
    --gradient-color-2: #72EBFE;
    --gradient-color-3: #FA9AFE;
    --gradient-color-4: #92ABFF;
  }

  .statue {
    position: absolute;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    width: 50%;
    top: -30px;
  }

  .shapes {
    position: absolute;
    z-index: 3;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;
    top: -20px;
  }

  .aliens {
    top: -40px;
    position: absolute;
    z-index: 3;
    left: 50%;
    width: 80%;
    animation: float 6s ease-in-out infinite;
  }

  @include breakpoint(small) {
    height: 450px;

    .statue {
      width: 80%;
      top: -50px;
    }

    .shapes {
      width: 90%;
      top: -10px;
    }

    .aliens {
      top: -40px;
      width: 95%;
      animation: float 6s ease-in-out infinite;
    }
  }
  @include breakpoint(large) {
    margin-right: 40px;
    height: 500px;

    .statue {
      max-width: 350px;
    }
  }
}


.menu__hold {
  position: relative;
  margin-top: 100px;
  margin-bottom: 50px;
}

.menu__inner {
  background: #A1ADFC;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.50);
  border-radius: 47px;
  padding: 85px 25px 25px;
  position: relative;

  .menu-sign {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 0;
    transform: translateX(-50%) translateY(-50%);
    width: 200px;
  }

  .menu__section {
    position: relative;
    z-index: 3;
    background: #230E47;
    border-radius: 47px;
    padding: 30px;
    margin-bottom: 30px;
    color: white;

    .unibody-bold {
      margin-bottom: 0;
    }

    ul {
      padding-bottom: 0;
      padding-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &::before, &::after {
    content: "";
    position: absolute;
  }

  &::before {
    border: 2px solid white;
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px;
    border-radius: 36px;
  }

  &::after {
    border: 1px solid white;
    top: 17px;
    right: 17px;
    bottom: 17px;
    left: 17px;
    border-radius: 31px;
  }

  .offer-icon, .floating-food, .menu-pictures {
    display: none;
  }

  @include breakpoint(small) {

    padding: 120px 60px 50px;

    .menu-sign {
      width: 300px;
    }

    .floating-food {
      display: block;
      width: 300px;
      position: absolute;
      z-index: 3;
      top: -150px;
      left: -140px;
      animation: floatNormal 6s ease-in-out infinite;
    }

    .menu-pictures {
      display: block;
      position: absolute;
      top: -39px;
      right: -28px;
      width: 294px;
    }

    .menu__section {
      position: relative;
      padding: 40px;


      .offer-icon {
        display: block;
        position: absolute;
        right: 40px;
        top: 20px;
        width: 142px;
      }
    }
  }


  @include breakpoint(medium) {
    padding: 135px 80px 65px;
    margin-top: 170px;


    .floating-food {
      width: 350px;
      position: absolute;
      top: -150px;
      left: -50px;
    }

    .menu-pictures {
      top: -40px;
      right: -28px;
      width: 350px;
    }


    .menu__section {
      padding: 55px;

      .offer-icon {
        top: 25px;
        right: 120px;
      }
    }
  }

  @include breakpoint(large) {
    padding: 135px 130px 65px;

    .menu-pictures {
      top: -73px;
      right: 40px;
      width: 392px;
    }

    .menu__section {

      .offer-icon {
        top: 35px;
        right: 200px;
      }
    }
  }
}

.menu__alien {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  text-align: right;

  .alien {
    width: 100px;
  }

  @include breakpoint(small) {
    .alien {
      width: 150px;
    }
  }
  @include breakpoint(medium) {
    .alien {
      width: 200px;
    }
  }
  @include breakpoint(large) {
    .alien {
      width: 250px;
    }
  }
}


@keyframes floatNormal {
  0% {
    transform: translatey(0px);
  }
  50% {

    transform: translatey(-15px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes floatOpp {
  0% {
    transform: translatey(0px);
  }
  50% {

    transform: translatey(15px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px) translateX(-50%);
  }
  50% {

    transform: translatey(-20px) translateX(-50%);
  }
  100% {
    transform: translatey(0px) translateX(-50%);
  }
}

@keyframes floatWeird {
  0% {
    transform: translatey(0px) translateX(-40%) translateY(-0%);
  }
  50% {

    transform: translatey(-20px) translateX(-40%) translateY(-0%);
  }
  100% {
    transform: translatey(0px) translateX(-40%) translateY(-0%);
  }
}