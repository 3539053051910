//=====================================
// #BUTTON
//=====================================
.btn {
  text-decoration: none;
  text-align: center;
  font-size: 1.8rem;
  line-height: 1;
  font-family: $heading-font;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 500;
  color: palette(white);
  border: 1px solid palette(white);
  outline: none;
  background-color: transparent;
  padding: 15px 40px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;

  &:hover, &:focus {
    border: 1px solid palette(pink);
    background: palette(pink);
    color: palette(white);
  }
}

.btn--disabled {
  opacity: .5;
  pointer-events: none;
}

.btn--full {
  text-align: center;
  width: 100%;
}

.btn--r {
  float: right;
}

//=====================================
// #BUTTON-SIZES
//=====================================

.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px;
}

.btn--large {
  font-size: 2.4rem;
  line-height: 3.2rem;
  padding: 12px 34px;
}

//=====================================
// #BUTTON-COLORS
//=====================================

.btn--black {
  border-color: palette(black, dark);
  color: palette(black, dark);

  &:hover, &:focus {
    border-color: lighten(palette(black, dark), 30%);
    color: lighten(palette(black, dark), 30%);
  }
}

.btn--black-fill {
  border-color: palette(black, dark);
  color: palette(white);
  background-color: palette(black, dark);

  &:hover, &:focus {
    background-color: lighten(palette(black, dark), 30%);
    border-color: lighten(palette(black, dark), 30%);
  }
}


.btn--white {
  border-color: palette(white);
  color: palette(white);

  &:hover, &:focus {
    border: 1px solid palette(pink);
    background: palette(pink);
    color: palette(white);
  }
}

.btn--white-fill {
  border-color: palette(white);
  background-color: palette(white);
  color: palette(black);

  &:hover, &:focus {
    border: 1px solid palette(pink);
    background: palette(pink);
    color: palette(white);
  }
}

.btn--pink-fill {
  background: palette(pink);
  border: 1px solid palette(pink);
  color: palette(black, dark);
  font-size: 2.4rem;

  &:hover, &:focus {
    background: lighten(palette(pink), 20%);
    border: 1px solid lighten(palette(pink), 20%);
    color: palette(black, dark);
  }
}

.btn--yellow {
  background-color: #FFDA00;
  border-color: #FFDA00;
  color: black;

  &:hover {
    background-color: #a68800;
    border: 1px solid #a68800;
    box-shadow: 0 0 10px 0 #a68800;
    color: black;
  }
}


.btn-old {
  display: inline-block;
  background-color: white;
  border: none;
  padding: 1px;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 1.2rem;
  font-family: unibody-8-new, sans-serif !important;
  font-weight: 700;
  transition:  all .3s;

  span {
    color: #230E47;
    line-height: 1.4;
  }

  .inside {
    background: #BFBFBF;
    box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.50);
    padding: 14px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
  }

  &:hover {
    background-color: #E1E1E1;
    border-right: 1px solid #E1E1E1;
    border-bottom: 1px solid #E1E1E1;

    .inside {
      background: #E1E1E1;
      box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0);
    }
  }
}