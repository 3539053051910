// ==========================================================================
// IMAGES
// ==========================================================================

.img--responsive {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.img--stretch {
  width: 100%;
}

.img--background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  @include cover();
  font-family: 'object-fit: cover;'; // Polyfill for object-fit
}

@include breakpoint(large) {
  .img--pull-left-large-up {
    max-width: 120% !important;
    margin-left: -10% !important;
  }

  .img--pull-right-large-up {
    max-width: 120% !important;
    margin-right: -10% !important;
  }
}

@include breakpoint(xlarge) {
  .img--pull-right-xlarge-up {
    max-width: 140% !important;
    margin-right: -20% !important;
  }
}
