//=====================================
// #TILE
//=====================================
.top-splitter {
  border-top: 10px solid rgba(255, 255, 255, .1);
}

.video__thumbnail {
  cursor: pointer;
  position: relative;
  height: auto;

  .video__thumbnail__decor {
    display: none;
  }

  .btn--pink-fill {
    display: block;
    position: absolute;
    z-index: 6;
    left: -20px;
    top: -20px;
    transform: rotate(-20deg) scale(0.8);
    pointer-events: none;
  }

  iframe, video {
    height: 250px;
    width: 100%;
  }

  .icon-button-help, .flying-aliens {
    display: none;
  }

  .video__button {
    position: absolute;
    z-index: 1000;
    top: 50%;
    left: 50%;
    height: 60px;
    width: 60px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    pointer-events: none;

    svg {
      transition: all .3s;
    }

    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  &:hover {
    .video__button svg {
      transform: scale(1.2);

      path {
        fill: #FF02B4;
      }
    }
  }
}

.tile--event {
  position: relative;
  color: white;
  padding-top: 10px;

  &--split {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 50px;
    margin-bottom: 50px;
  }

  h3 {
    display: inline-block;
    margin-bottom: 10px;
    padding-right: 15px;
  }

  .tile__head {
    position: relative;
    display: inline-block;
  }

  .soldout {
    position: absolute;
    top: -10px;
    right: 20px;
    transform: skew(-20deg);
    background-color: palette(teal);
    font-style: italic;
    font-family: $heading-font;
    text-transform: uppercase;
    padding: 5px;
    color: #230E47;
    font-size: 1.4rem;

    span {
      transform: skew(20deg);
      display: block;
    }
  }


  .date {
    text-transform: uppercase;
    margin-bottom: 0;
    color: palette(teal);
    font-weight: bold;
  }

  .info {
    margin-bottom: 30px;
    opacity: .5;
  }
}

.window {
  background: #fbf8fd;
  position: relative;
  margin-bottom: 30px;
  padding: 1px;


  .bkg-1, .bkg-2, .speech, .ufo, .shapes, .flying-alien-group, .flying-alien-group-bottom, .flying-alien-code {
    display: none;
  }


  .inner {
    position: relative;
    z-index: 3;
    height: 100%;
    width: 100%;
    background: #bfbfbf;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, .65);
    padding: 2px;
  }

  .title {
    position: relative;
    background-color: #230E47;
    padding: 12px 8px 9px;
    font-family: unibody-8-new, sans-serif !important;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.5;
    color: #FF02B4;

    .cross {
      position: absolute;
      top: 4px;
      bottom: 4px;
      right: 4px;
      width: 30px;
      background: #bfbfbf;
      box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .5);

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .panel {
    padding: 20px;

    img {
      margin-bottom: 20px;
    }
  }

  .window__image {
    margin-bottom: -15px;
    margin-left: -15px;
    margin-right: -15px;
  }

  .window__code {
    background: #230E47;
    margin-bottom: -15px;
    margin-left: -15px;
    margin-right: -15px;
    padding: 25px 20px 25px 5px;

    ul {
      list-style: none;

      li {
        position: relative;
        padding-left: 110px;
        font-size: 1.4rem;
        padding-bottom: 35px;

        &:last-of-type {
          padding-bottom: 0;
        }

        &::before {
          content: "C:/BADA>";
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }

    * {
      color: white;
    }
  }
}

.menu-follower {
  position: fixed;
  z-index: 10;
  left:0;
  top:86%;
  transform: translateY(-50%);
  width: 180px;

  .window {
    position: relative;

    .drink, .burger {
      position: absolute;
      z-index: 4;
    }

    .drink {
      width: 50px;
      top: -70px;
      left: -20px;
    }

    .burger {
      width: 80px;
      bottom: -48px;
      right: 6px;
    }

    .panel {
      text-align: center;
      padding: 10px;

      .btn-old {
        width: 100%;

        .inside {
          justify-content: center;
        }
      }
    }
  }

  @include breakpoint(small) {
    top: 50%;
  }
  @include breakpoint(large) {
    width: 200px;
  }
}

@include breakpoint(small) {

  .video__thumbnail {

    .btn--pink-fill {
      transform: rotate(-20deg) scale(1);
    }

    iframe, video {
      height: 393px;
    }

    .video__thumbnail__decor {
      display: block;
      position: absolute;
      bottom: -30px;
      right: 30px;
      animation: floatNormal 6s ease-in-out infinite;

      &, svg {
        width: 159px;
        height: 181px;
      }

      img {
        max-width: 275px;
      }
    }
  }

  .window {
    &--address {
      margin-top: 85px;
    }

    &--address-only {
      margin-bottom: 60px;
    }

    .panel {
      img {
        margin-bottom: 0;
      }
    }

    .speech, .shapes, .ufo, .flying-alien-group, .flying-alien-group-bottom, .flying-alien-code {
      display: block;
      position: absolute;
      z-index: 5;
    }

    .speech {
      width: 150px;
      height: 110px;
      right: -142px;
      top: 30px;
    }

    .flying-alien-group {
      width: 300px;
      animation: floatNormal 6s ease-in-out infinite;
      right: -40px;
      top: -50px;
    }

    .flying-alien-group-bottom {
      width: 500px;
      animation: floatNormal 6s ease-in-out infinite;
      left: -40px;
      bottom: -50px;
      z-index: 2;
    }

    .flying-alien-code {
      width: 145px;
      animation: floatNormal 6s ease-in-out infinite;
      left: -50px;
      bottom: -130px;
    }

    .shapes {
      bottom: -50px;
      left: 0;
      width: 122px;
      height: 162px;
    }

    .ufo {
      bottom: 100%;
      right: 0;
      width: 250px;
    }

    .bkg-1 {
      display: block;
      position: absolute;
      top: -20px;
      left: -20px;
    }

    .bkg-2 {
      display: block;
      position: absolute;
      top: 20px;
      right: -20px;
    }
  }

  .bottom-window {
    margin-top: -100px;
  }


  .tile--event {
    text-align: left;
  }
}

@include breakpoint(medium) {


  .video__thumbnail {
    iframe, video {
      height: 517px;
    }
  }

  .window {
    .ufo {
      right: -60px;
      width: 380px;
    }

    .speech {
      width: 200px;
      height: 148px;
      right: -180px;
      top: 5px;
    }

    .flying-alien-group {
      width: 450px;
      right: -150px;
      top: -100px;
    }

    .flying-alien-group-bottom {
      width: 700px;
      left: -70px;
      bottom: -50px;
    }
  }

  .tile--textImageBlock {
    img {
      padding-left: 50px;
    }
  }
}

@include breakpoint(large) {

  .video__thumbnail {
    iframe, video {
      height: 696px;
    }
  }

  .window {
    .ufo {
      right: -60px;
      width: 470px;
    }

    .flying-alien-group {
      width: 520px;
      right: -120px;
      top: -100px;
    }

    .flying-alien-group-bottom {
      width: 820px;
      left: -70px;
      bottom: -70px;
    }
  }
}
