.navigation-button {
  position: relative;
  z-index: 10;
  display: inline-block;

  &:hover {
    .start {
      background-color: transparent;

      span {
        color: palette(black, dark);
      }
    }
  }
}

.start {
  font-family: $heading-font;
  cursor: pointer;
  background-color: palette(black, dark);
  border: 2px solid palette(black, dark);
  padding: 0 1px 1px 0;
  transition: all .3s;

  span {
    color: palette(white);
    line-height: 1;
    font-family: $heading-font;
    font-size: 2.4rem;
    text-transform: uppercase;
    transition: color 0.3s;
  }

  .inside {
    padding: 10px 13px 10px 13px;
  }
}

@include breakpoint(small) {
  .start {
    width: 115px;

    .inside {
      width: 111px;
    }
  }
}

