// ==========================================================================
// Breakpoints
// ==========================================================================

@mixin breakpoint($point) {
  @if $point == x-small {
    @media (max-width: 767px) { @content; }
  }
  @if $point == small {
    @media (min-width: 768px) { @content; }
  } @else if $point == medium {
    @media (min-width: 992px) { @content; }
  } @else if $point == large {
    @media (min-width: 1260px) { @content; }
  } @else if $point == x-large {
    @media (min-width: 1440px) { @content; }
  } @else if $point {
    @media (min-width: $point) { @content; }
  }
}

@mixin breakpoint-max($point) {
  @if $point == small {
    @media (max-width: 768px) { @content; }
  } @else if $point == medium {
    @media (max-width: 992px) { @content; }
  } @else if $point == large {
    @media (max-width: 1220px) { @content; }
  } @else if $point {
  @media (max-width: $point) { @content; }
}
}

@mixin vert_breakpoint($point) {
  @if $point == mini {
    @media (max-height: 840px) {
      @content;
    }
  }
  @if $point == pro {
    @media (max-height: 950px) {
      @content;
    }
  } @else if $point {
    @media (max-height: $point) {
      @content;
    }
  }
}

@mixin mini() {
  @media (max-height: 840px) and (min-width: 992px) {
    @content;
  }
}

$grids: (
        "x-small": (
                width: 480px
        ),
        "small": (
                width: 768px
        ),
        "medium": (
                width: 992px
        ),
        "large": (
                width: 1220px
        )
) !global;

body.debug {
  &:after { // fixed elem that shows which bp the viewport is at
    background-color: rgba(0, 0, 0, .3);
    color: white;
    text-transform: uppercase;
    padding: 17px 25px;
    position: fixed;
    font-size: 15px;
    font-weight: 700;
    bottom: 45px;
    right: 0;
    z-index: 999;

    @each $breakpoint, $config in $grids {
      @if map-get($config, width) {
        @include breakpoint($breakpoint) {
          content: $breakpoint
        }
      }
    }
  }
}
