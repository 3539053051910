// ==========================================================================
// #FOOTER
// ==========================================================================

.footer {
  overflow: hidden;
  position: relative;
  z-index: 2;
  background-color: black;
  padding-bottom: 120px;

  .footer__social {
    margin-right: 0;
  }

  .footer__logo {
    margin: 0 auto;

    &, svg {
      width: 100px;
    }
  }

  .footer__aux {
    a {
      display: inline-block;
      padding-bottom: 15px;
    }
  }

  .footer__text {
    position: relative;

    p:last-of-type {
      margin-bottom: 0;
    }

    .shapes {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -140px;
      width: 100px;
    }
  }

  .footer__logos {
    .g__1-2 {
      padding: 10px;

      img {
        max-height: 58px;
      }
    }
  }
}

@include breakpoint(small) {
  .footer {
    overflow: hidden;
  }
}

@include breakpoint(medium) {
  .footer {
    padding-bottom: 80px;

    .footer__text {
      .shapes {
        left: -70px;
        transform: none;
        bottom: -65px;
        width: 110px;
      }
    }

    .footer__logo {
      &, svg {
        width: 120px;
        height: 47px;
      }
    }
  }
}

@include breakpoint(large) {
  #footer {
    padding-bottom: 100px;

    .footer__text {
      .shapes {
        left: -90px;
        transform: none;
        bottom: -80px;
      }
    }
  }
}