//=====================================
// #SLIDER
//=====================================

.events__slider {
  .item {
    width: 100%;
    padding-right: 25px;
    padding-left: 5px;
    padding-top: 32px;
  }

  &:after {
    content: 'flickity';
    display: none; /* hide :after */
  }
}

.events__slider__controls {
  .events__slider__control {
    background: none;
    border: none;
    opacity: 0.5;
    transition: opacity 0.2s;
    cursor: pointer;

    > .icon {
      width: 60px;
      height: auto;
    }

    @include breakpoint(medium) {
      &:hover {
        opacity: 1;
      }
    }
  }
}

.event__slider__button {
  @include breakpoint(small) {
    position: relative;
    transform: rotate(12deg);
    transform-origin: center;
  }
}

@include breakpoint-max(small) {
  .events__slider__controls {
    display: none;
  }

  .events__slider {
    .item {
      margin-bottom: 30px;
    }

    &:after {
      content: '';
    }
  }

  .tile--event {
    border-bottom: 1px solid white;
    padding-bottom: 25px;
  }
}

@include breakpoint(small) {
  .events__slider {
    .item {
      width: 50%;
    }
  }

  .event__slider__button {
    position: relative;
  }
}

@include breakpoint(medium) {
  .events__slider {
    .item {
      width: 25%;
    }
  }
}

@include breakpoint(large) {

}
