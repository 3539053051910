/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/

.g {
  width: 100%;
  lost-flex-container: row;

  > * {
    width: 100%;
  }
}

.g--ng {
  > * {
    width: 50%;
    flex-basis: 50%;
  }
}

.g__1 {
}

.g__ac {
  align-items: center;
}

.g__1-2 {
  lost-column: 1/2;
}

.g__1-3 {
  lost-column: 1/3;
}

.g__2-3 {
  lost-column: 2/3;
}

.g--gutter {
  .g__1-2 {
    lost-column: 1/2 2 gutter(1);
  }

  .g__1-3 {
    lost-column: 1/3 3 gutter(1);
  }

  .g__2-3 {
    lost-column: 2/3 3 gutter(1);
  }
}

.g--grid {
  align-items: center;
}

@include breakpoint(small) {

  .gs__ac {
    align-items: center;
  }

  .g--gutter {
    .gs__1-2 {
      lost-column: 1/2 2 gutter(1);
    }
  }

  .g--gutter-none {
    .gs__1-2 {
      lost-column: 1/2 0 0;
    }
  }


  .g--window {
    .gs__1-4 {
      lost-column: 1/4 0 15px;
    }

    .gs__3-4 {
      lost-column: 3/4 0 15px;
    }
  }

  .gs__1-2 {
    lost-column: 1/2;
  }

  .gs__1-3 {
    lost-column: 1/3;
  }

  .gs__2-3 {
    lost-column: 2/3;
  }

  .gs__1-4 {
    lost-column: 1/4;
  }

  .gs__3-4 {
    lost-column: 3/4;
  }


  .gs__m1-3 {
    lost-move: 1/3;
  }

  .gs__m-1-3 {
    lost-move: -1/3;
  }

  .gs__m-1-2 {
    lost-move: -1/2;
  }

  .gs__m1-2 {
    lost-move: 1/2;
  }

  .gs__m2-3 {
    lost-move: 2/3;
  }

}

@include breakpoint(medium) {

  .gm__ac {
    align-items: center;
  }


  .g--gutter-none {
    .gm__1-3 {
      lost-column: 1/3 0 0;
    }

    .gm__1-4 {
      lost-column: 1/4 0 0;
    }
  }

  .g--gutter {
    .gm__1-2 {
      lost-column: 1/2 2 gutter(1.5);
    }
  }

  .gm__1-2 {
    lost-column: 1/2;
  }


  .gm__1-3 {
    lost-column: 1/3;
  }

  .gm__m-1-3 {
    lost-move: -1/3;
  }

  .gm__m1-3 {
    lost-move: 1/3;
  }

  .gm__m0 {
    left: 0 !important;
  }

  .gm__1-4 {
    lost-column: 1/4;
  }


  .gm__1-3 {
    lost-column: 1/3;
  }

  .gm__2-3 {
    lost-column: 2/3;
  }


  .gm__2-5 {
    lost-column: 2/5;
  }

  .gm__3-5 {
    lost-column: 3/5;
  }

  .gm__7-10 {
    lost-column: 7/10;
  }

  .gm__3-10 {
    lost-column: 3/10;
  }

  .gm__5-12 {
    lost-column: 5/12;
  }

  .gm__7-12 {
    lost-column: 7/12;
  }
}

@include breakpoint(large) {

  .g--gutter-none {
    .gl__1-4 {
      lost-column: 1/4 0 0;
    }
  }

  .g--gutter {
    .gl__1-3 {
      lost-column: 1/3 3 gutter(1.5);
    }
  }

  .gl__1-3 {
    lost-column: 1/3;
  }

  .gl__1-4 {
    lost-column: 1/4;
  }

  .gl__3-4 {
    lost-column: 3/4;
  }
}
