// ==========================================================================
// DECOR
// ==========================================================================

.decor__hold {
  position: relative;
  z-index: 1;
}

.decor {
  &.decor--top {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-85%);
      height: 60px;
      width: 100%;

      @include breakpoint(small) {
        height: 100px;
      }
    }
  }

  &.decor--bottom {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(85%);
      height: 60px;
      width: 100%;

      @include breakpoint(small) {
        height: 100px;
      }
    }
  }

  &.decor--black {
    &.decor--top {
      &:before {
        background: url(../img/decor/decor-top-black.svg);
        background-size: 1800px 100px;
        background-position: -400px top;
      }
    }

    &.decor--bottom {
      &:after {
        background: url(../img/decor/decor-bottom-black.svg);
        background-size: 1800px 100px;
        background-position: -400px top;
      }
    }
  }

  &.decor--pink {
    &.decor--top {
      &:before {
        background: url(../img/decor/decor-top-pink.svg);
        background-size: 1800px 100px;
        background-position: -400px top;
      }
    }

    &.decor--bottom {
      &:after {
        background: url(../img/decor/decor-bottom-pink.svg);
        background-size: 1800px 100px;
        background-position: -400px top;
      }
    }
  }
}