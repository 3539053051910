// ==========================================================================
// #HEADER
// ==========================================================================
#header {
  height: 61px;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 5;
  background-color: #FF02B4;

  .wrapper {
    position: relative;
    z-index: 5;
  }

  .inner {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .socials {
    display: none;
  }


  .logo {
    display: inline-block;
    vertical-align: middle;

    &, svg {
      width: 100px;
      height: 39px;
    }
  }

  .links {
    vertical-align: middle;
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;

    li {
      padding-right: 20px;
      display: inline-block;
    }

    a {
      position: relative;
      color: white;
      font-weight: bold;
      font-family: $heading-font;
      font-style: italic;
      text-transform: uppercase;
      padding-left: 8px;

      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 30px;
        height: 3px;
        margin-top: 3px;
        background-color: transparent;
        transition: background-color .3s;
      }

      &:hover::before {
        background-color: #DA32E1;
      }

      &.link--active {
        color: #DA32E1;

        &::before {
          background-color: #DA32E1;
        }
      }
    }
  }

  .navigation__image {
    display: none;
  }

  .navigation {
    padding-top: 61px;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    transition: opacity .1s ease-out, visibility .1s;

    .inner {
      padding: 30px gutter(2) 150px;
      overflow: auto;
      overflow-x: hidden;
      background-color: #000000;
      height: 100%;
      text-align: left;
    }

    a {
      color: white;
    }

    .heading {
      font-family: $heading-font;
      font-style: italic;
      text-transform: uppercase;
      line-height: 1;
      font-weight: bold;
      font-size: 3.5rem;
      transition: color .3s;
      white-space: nowrap;
    }

    li {
      display: block;
      padding-bottom: 25px;
    }

    .navigation__decor {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 140px;
      display: none;

      @include breakpoint(small) {
        display: block;
        width: 180px;
      }

      @include breakpoint(medium) {
        width: 220px;
      }

      @include breakpoint(large) {
        width: 320px;
      }

      @include breakpoint(xlarge) {
        width: 400px;
      }
    }

    &.open {
      visibility: visible;
      transition: opacity .3s ease-out, visibility .3s;
      opacity: 1;
    }
  }
}

.body--invert {
  #header {
    .links a {
      color: #051127;
    }

    .logo svg path {
      fill: #051127;
    }
  }
}

@include breakpoint(small) {
  #header {
    height: 70px;

    .inner {
      padding: 12px 0;
    }

    .socials {
      display: block;
    }

    .logo {
      &, svg {
        width: 120px;
        height: auto;
      }
    }

    .navigation {
      padding-top: 70px;

      .inner {
        padding: 20px gutter(2) 0;
      }

      .navigation__image {
        display: block;
      }

      .heading {
        font-size: 5rem;
      }

      li {
        padding-bottom: 35px;
      }

      .gs__1-4 {
        position: relative;
        z-index: 10;
      }
    }
  }
}

@include breakpoint(medium) {
  #header {
    height: 90px;

    .inner {
      padding: 18px 0;
    }

    .logo {

      &, svg {
        width: 145px;
        height: auto;
      }
    }

    .navigation {
      padding-top: 90px;

      .inner {
        padding: 20px gutter(2) 0;
      }

      .heading {
        font-size: 6rem;
      }

      li {
        padding-bottom: 35px;
      }
    }
  }
}