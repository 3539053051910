//=====================================
// #HEADINGS
//=====================================
$h1-size: 6.4rem;
$h2-size: 4.8rem;
$h3-size: 2.8rem;
$h4-size: 2.4rem;

$p-size: 1.8rem;
$p-size--small: 1.6rem;
$p-size--big: 2.2rem;

$heading-font: proxima-nova, sans-serif;
$body-font: sofia-pro, sans-serif;

.sofiapro-light {
  font-family: sofia-pro, sans-serif;
  font-weight: 300;
  font-style: normal !important;
}

.proxima-nova {
  font-family: proxima-nova, sans-serif;
  font-weight: 900;
  font-style: italic !important;
}


.unibody-black {
  font-family: unibody-8-new, sans-serif !important;
  font-weight: 900;
}