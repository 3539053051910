//Clearfix
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clear {
  clear: both;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// Example:
//
//.container-with-floated-children {
//  @extend %clearfix;
//}