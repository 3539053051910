//=====================================
// #HERO
//=====================================
.hero {
  margin-top: 61px;
  position: relative;

  .hero__item {
    display: none;
  }
}

.hero__learnmore {
  display: block;
  background-color: palette(black, dark);
  padding: 20px;
  text-transform: uppercase;
  transition: all .3s;
  z-index: -1;

  p:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    color: white;
    background: palette(pink);
    box-shadow: 0 0 10px 0 palette(pink);
  }
}

.hero--full {
  margin-top: 61px;
  position: relative;
  background-color: palette(pink);

  .hero__text-popup {
    position: relative;
    z-index: 9;
    background-color: palette(white);
    padding: 30px;
    margin-left: auto;
    margin-right: auto;
    max-width: 480px;

    @include breakpoint(small) {
      margin-top: 25vh;
    }
  }

  .inner {
    position: relative;
    overflow: hidden;
  }

  .coming-soon {
    display: none;
  }

  &::before {
    content: "";
    z-index: 2;
    @include cover();
    background-color: black;
    opacity: .4;
  }

  @include breakpoint-max(small) {
    .no-mobile {
      display: none;
    }
  }

  .hero__decor {
    position: absolute;
    z-index: 3;
    right: 0;
    bottom: 0;
    transform: translate(-10px, 20px);
    width: 120px;
    pointer-events: none;

    @include breakpoint(375px) {
      width: 140px;
    }
  }

  video {
    display: block;
    width: 100%;
    position: relative;
  }
}

.hero--legals {
  padding-top: 20vh;

  .hero__flare {
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 25px;

    &, svg {
      width: 120px;
      height: 251px;
    }
  }
}

.hero--sub {
  color: white;
  padding: 16vh 0 14vh;

  .wrapper {
    padding: 0 gutter(2);
  }

  .h1 {
    margin: 0;

    @include breakpoint-max(small) {
      font-size: 3.5rem;
    }
  }

  .img--background {
    opacity: .3;
  }
}

.overlay {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: gutter(1);
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity .3s, visibility .3s;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .inner {
    background-color: white;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    max-width: 740px;
    padding: 25px;
    text-align: left;
    border-radius: 15px;

    .inner__cross {
      position: absolute;
      top: 25px;
      right: 25px;
    }
  }

  .popup_align {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}

@include breakpoint-max(small) {

  .overlay {
    .inner {
      height: 100%;
      overflow: scroll;
    }
  }
}

@include breakpoint(small) {

  .overlay {
    .inner {
      padding: 45px 70px;
    }
  }

  .hero {
    margin-top: 70px;

    .hero__item {
      display: block;
      position: absolute;
      width: 170px;
      z-index: 4;
      bottom: -50px;

      img {
        opacity: 1;
      }

      &--left {
        left: 5%;
        animation: floatNormal 6s ease-in-out infinite;
      }

      &--right {
        right: 5%;
        animation: floatOpp 6s ease-in-out infinite;
      }
    }
  }

  .hero--full {
    margin-top: 70px;
    min-height: 85vh;

    .inner {
      min-height: 85vh;
      overflow: hidden;
    }

    .hero__decor {
      transform: translate(-60px, 60px);
      width: 280px;
    }

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: 0;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .hero--sub {
    color: palette(white);
    padding: 25vh 0 26vh;
  }
}

@include breakpoint(medium) {
  .hero--full {
    .hero__decor {
      transform: translate(-80px, 80px);
      width: 400px;
    }
  }

  .hero--legals {
    .hero__flare {
      margin-right: 30px;

      &, svg {
        width: 200px;
        height: 418px;
      }
    }
  }

  .hero {
    .hero__item {
      width: 220px;
      bottom: -65px;

      &--left {
        left: 5%;
      }

      &--right {
        right: 5%;
      }
    }
  }
}

@include breakpoint(large) {
  .hero {
    .hero__item {
      width: 250px;
      bottom: -25px;

      &--left {
        left: 8%;
      }

      &--right {
        right: 8%;
      }
    }
  }

  .hero--full {
    .hero-decor {
      transform: translate(80px, 80px);
      width: 450px;
    }
  }

  .hero--legals {
    .hero__flare {
      margin-right: 60px;

      &, svg {
        width: 240px;
        height: 502px;
      }
    }
  }
}