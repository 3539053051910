//=====================================
// #BOX
//=====================================

// Box location
//

.box-location {
  position: relative;
  background-color: palette(pink);
  background-image: url("/dist/img/bkg/graffiti-purple-small.jpg");
  background-position: center center;
  background-size: cover;
  color: palette(white);
  text-align: center;
  padding: 32px 20px;

  @include breakpoint(400px) {
    padding: 32px 44px;
  }

  @include breakpoint(small) {
    background-image: url("/dist/img/bkg/graffiti-purple.jpg");
  }

  @include breakpoint(medium) {
    padding: 68px;
  }

  .box-location__decor-1 {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(5px) translateX(-40px);
    width: 120px;
    display: none;

    @include breakpoint(small) {
      width: 150px;
      display: block;
    }

    @include breakpoint(large) {
      width: 220px;
    }
  }

  .box-location__decor-2 {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-100px) translateX(-5px);
    width: 150px;
    display: none;

    @include breakpoint(small) {
      width: 180px;
      display: block;
    }

    @include breakpoint(large) {
      width: 260px;
    }
  }
}

// Box signup
//

.box-signup__container {
  position: relative;
  z-index: 1;
  padding-left: 10px;
  padding-right: 10px;
}

.box-signup {
  position: relative;
  background-color: palette(pink);
  padding: 32px 20px;
  max-width: 1170px;
  margin: 0 auto;

  @include breakpoint(400px) {
    padding: 32px 44px;
  }

  @include breakpoint(medium) {
    padding: 68px;
  }

  @include breakpoint(large) {
    padding: 68px 90px;
  }

  .box-signup__decor-1 {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-25%) translateX(-60px);
    width: 200px;
    z-index: -1;
    display: none;

    @include breakpoint(small) {
      display: block;
      width: 320px;
    }

    @include breakpoint(large) {
      width: 440px;
    }
  }

  .box-signup__decor-2 {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(40%) translateX(40px);
    width: 240px;
    display: none;

    @include breakpoint(small) {
      width: 350px;
      display: block;
    }

    @include breakpoint(large) {
      width: 450px;
    }
  }
}