//=====================================
// #FAQS
//=====================================
.faq__types {
  .checkbox__group {
    .inner-box {
      position: relative;
      z-index: 3;
    }
  }
}

.faq__type-item {
  position: relative;
  display: block;
  font-size: 1.2rem;
  height: 35px;
  color: #230E47;
  padding-left: 45px;
  padding-top: 12px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 35px;
    height: 35px;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    background: #C0C0C0;
  }

  &--active {
    &::before {
      border-top: 1px solid black;
      border-left: 1px solid black;
      border-right: 1px solid white;
      border-bottom: 1px solid white;
      background: #E1E1E1;
    }
  }
}

.faq__card {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .faq__top {
    cursor: pointer;
    position: relative;
    background: #230E47;
    padding: 20px 70px 20px 60px;

    .q {
      position: absolute;
      top: 4px;
      left: 4px;
      bottom: 4px;
      width: 40px;
      text-align: center;
      background: #A1ADFC;
      font-size: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      color: #FF02B4;
      font-size: 1.4rem;
      margin-bottom: 0;
    }

    .accordion__button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      transition: transform .3s;
    }
  }

  .button__active {

    .accordion__button {
      transform: translateY(-50%) rotate(180deg);
    }

    .flip {
      fill: #EA38B0;
    }

    .flip-dark {
      fill: #F471CF;
    }
  }

  .faq__answer {
    position: relative;
    background: white;
    padding: 20px 20px 20px 60px;
    display: none;

    p:last-of-type {
      margin-bottom: 0;
    }

    &--active {
      display: block;
    }

    .a {
      position: absolute;
      top: 4px;
      left: 4px;
      bottom: 4px;
      width: 40px;
      text-align: center;
      background: #A1ADFC;
      font-size: 2.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@include breakpoint(small) {

}

@include breakpoint(medium) {
  .faq__card-hold {
    padding-left: 50px;
  }
}

@include breakpoint(large) {

}