//=====================================
// #FORM
//=====================================
input {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid palette(black, dark);
  border-radius: 0;
  color: palette(black);
  background: transparent;
  font-family: $body-font;
  font-size: 2rem;
  line-height: 1.5;
  width: 100%;
  padding: 8px 0;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  -webkit-background-clip: text !important;
  transition: background .3s, border .5s;

  @include breakpoint(medium) {
    font-size: 2.4rem;
  }

  &:focus {
    border-bottom: 1px solid lighten(palette(black, dark), 10%);
  }

  &:not(:placeholder-shown) {
    background-color: transparent;
  }

  &::placeholder {
    color: palette(black, dark);
    font-weight: bold;
    text-align: center;
    font-size: 2rem;
    line-height: 1.5;

    @include breakpoint(medium) {
      font-size: 2.4rem;
    }
  }

  &[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed;
  }

  &.input--solid {
    background: palette(white);
    border-bottom: none;
    padding: 8px 12px;
    -webkit-background-clip: unset !important;

    &::placeholder {
      text-align: left;
    }
  }

  &.input--left {
    &::placeholder {
      text-align: left;
    }
  }

  &.input--light {
    color: palette(white);
    border-bottom: 1px solid palette(white);

    &::placeholder {
      color: palette(white);
    }
  }
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0;
}

.input--hold {
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 10px;
  }
}

textarea {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid palette(black, dark);
  border-radius: 0;
  color: #230E47;
  background: white;
  font-family: $body-font;
  font-size: 2rem;
  line-height: 1.5;
  width: 100%;
  padding: 8px 0;
  outline: none;
  transition: background .3s, border .5s;

  @include breakpoint(medium) {
    font-size: 2.4rem;
  }

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:empty) {
    background-color: #fbfbfb;
  }
}

select {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid palette(black, dark);
  background-color: transparent;
  font-family: $body-font;
  line-height: 1.5;
  width: 100%;
  padding: 10px 0;
  outline: none;
  transition: background .3s, border .5s;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  background-image: url('/dist/img/icons/arrow-down-simple.svg');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 18px;
  color: palette(black, dark);
  font-weight: bold;
  font-size: 2rem;

  @include breakpoint(medium) {
    font-size: 2.4rem;
  }

  &.select--light {
    background-image: url('/dist/img/icons/arrow-down-simple-light.svg');
    color: palette(white);
    border-bottom: 1px solid palette(white);

    option {
      color: palette(black, dark);
    }
  }
}

//=====================================
// #FORM-TYPES
//=====================================
.input__hold--fancy {
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative;

  label {
    margin-top: 12px;
    margin-bottom: 0;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    position: absolute;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    color: #333;
    pointer-events: none;
    font-weight: 400;
    font-size: 18px;
    left: 0;
    top: 50px;

    span {
      padding-left: 10px;
      font-size: 1rem;
      display: inline-block;
      color: #c7192e;
    }
  }

  input, textarea, select {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: #333;
  }

  input {

    &:focus ~ label, &.active--input ~ label {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px;
    }

    &[type=file] {
      border: 0;
      padding-top: 0;
      width: 100%;
      display: flex;
    }
  }

  textarea {

    &:focus ~ label, &.active--input ~ label {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      font-size: 14px;
    }
  }

  select {
    width: 100%;
    font-size: 18px;
    transition: border .5s;
  }

  &.active--input select {
    border-bottom: 2px solid white;

    ~ label {
      color: #333;
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px;
    }
  }
}

// Honey pot
#aName {
  display: none;
}

.checkbox__group {
  position: relative;
  border-bottom: 1px solid palette(black, dark);

  .bump .big {
    font-family: $heading-font;
  }
}

.checkbox {
  display: flex;

  &--center {
    justify-content: center;
  }

  label {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    line-height: 1.6;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px;

    &::before, &::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block;
    }

    &.outline--blue::before {

    }

    &::before {
      top: 3px;
      left: 0;
      width: 18px;
      height: 18px;
      border: none;
      background-color: palette(white);
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
    }

    &::after {
      color: #230E47;
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
    }
  }

  input[type="checkbox"] {

    + label::after {
      content: none;
    }

    &:checked + label {
      &:before {
        //background-color: #DA32E1;
        //border-color: #DA32E1;
      }

      &::after {
        content: "";
      }
    }

    &:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }

    &:disabled ~ label {
      &:before, &:after {
        opacity: .5
      }
    }
  }

  &.error {
    label::before, label::after {
      animation: shake .5s ease-in-out forwards;
      //transform: scale(1.2);
    }
  }

  &--big {
    label {
      font-size: 2.2rem;
      font-weight: bold;
      padding-top: 4px;
      padding-left: 40px;
      line-height: 1;

      &::before {
        height: 30px;
        width: 30px;
      }

      &::after {
        height: 10px;
        width: 18px;
        border-left: 3px solid;
        border-bottom: 3px solid;
        transform: rotate(-52deg);
        left: 6px;
        top: 10px;
      }
    }
  }
}

.error {
  margin-top: 15px;
  display: inline-block;
  padding: 15px;
  border: 2px solid palette(red, error);
  font-size: $p-size;
}

//=====================================
// #FORM-COLORS
//=====================================
.input--success {
  border-color: palette(green, success);
}

.input--error {
  border-color: palette(red, error);
}

.form-overlay {
  display: none;
  z-index: 20;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(35, 14, 71, .75);

  .inner {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 970px;
    border: 5px solid palette(white);
    color: palette(white);
    text-align: center;
    background-image: url("/dist/img/bkg/graffiti-purple.jpg");
    background-position: center center;
    background-size: cover;
    padding: 80px 20px;

    h2 {
      margin-bottom: 0;
      max-width: 500px;
      margin: 0 auto;
    }

    @include breakpoint(medium) {
      border: 10px solid palette(white);
      padding: 140px 20px;
    }

    .form-overlay__decor-1 {
      position: absolute;
      top: -40px;
      left: 20px;
      width: 200px;
      display: none;

      @include breakpoint(small) {
        display: block;
      }

      @include breakpoint(medium) {
        top: -100px;
        left: 30px;
        width: 350px;
      }
    }

    .form-overlay__decor-2 {
      position: absolute;
      bottom: -40px;
      right: -20px;
      width: 150px;
      display: none;

      @include breakpoint(small) {
        display: block;
      }

      @include breakpoint(medium) {
        bottom: -80px;
        right: -50px;
        width: 250px;
      }
    }
  }

  .form-overlay__close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(-150%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: palette(white);
    border-radius: 50%;
    border: none;
    padding: 10px;
  }

  &--complete {
    display: block;
  }
}


.event {
  padding: 10px;
  border: 2px solid red;
  text-align: center;
  margin-bottom: 0;
  margin-top: 10px;
}

.FormDate {
  position: relative;
  overflow: hidden;
  // 1. Hide the spinner button in Chrome, Safari and Firefox.
  &__input {
    text-align: left;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield; // 1
    &::-webkit-inner-spin-button {
      display: none; // 1
    }

    &::placeholder {
      text-align: left;
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
  }

  &__divider {
    pointer-events: none;
  }
}