//=====================================
// #PAGINATION
//=====================================
.pagination {
  text-align: center;

  a, span {
    padding: 0 2px;
    margin: 0 8px;
    color: black;
    font-weight: 300;

    @include breakpoint(medium) {
      margin: 0 12px;
    }
  }

  .icon {
    padding: 0;
    opacity: .5;
    transition: opacity .5s;

    &:hover {
      opacity: 1;
    }
  }

  .previous {
    margin-right: 10px;
  }
  .next {
    margin-left: 10px;
  }

  @include breakpoint(medium) {
    .previous {
      margin-right: 20px;
    }
    .next {
      margin-left: 20px;
    }
  }

  span {
    &.current {
      border-bottom: 2px solid blue;
    }
  }
}