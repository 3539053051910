//=====================================
// #HEADINGS
//=====================================
h1, .h1,
h2, .h2,
h3, .h3 {
  font-family: $heading-font;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 25px;
  text-transform: uppercase;
  font-style: italic;
}

h1, .h1 {
  font-size: 6rem;
  @include breakpoint(small) {
    font-size: $h1-size;
  }
}

h2, .h2 {
  font-size: 4rem;
  @include breakpoint(small) {
    font-size: $h2-size;
  }
}

h3, .h3 {
  font-size: $h3-size;
}

h4, .h4 {
  font-size: $h4-size;
}

.h--grid {
  margin-bottom: 30px;

  h2, h3 {
    margin-bottom: 10px;
  }

  .right {
    margin-bottom: 25px;
  }

  @include breakpoint(small) {
    margin-bottom: 50px;

    h2, h3, .right {
      margin-bottom: 0;
    }
  }
}

.tagline {
  text-transform: uppercase;
}

.h-link {
  position: relative;

  &::before {
    content: "";
    height: 8px;
    width: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: 0;
    transition: width .6s;
    background: #FF02B4;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
}

//=====================================
// #TEXT
//=====================================
p, .p, label {
  font-family: $body-font;
  font-size: $p-size;
  line-height: 1.9;
  margin-bottom: 20px;
  font-weight: 300;
}

.small > p,
.small > .p,
.small {
  font-size: $p-size--small;
}

.big > p,
.big > .p,
.big {
  font-size: $p-size--big;
}

a, .link--fake {
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  transition: color .3s;
  color: inherit;
}

p a, label a {
  text-decoration: underline;
  color: inherit;
}

.link--icon {
  span {
    margin-left: 10px;
  }
}


//=====================================
// #LISTS
//=====================================
ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px;

  &.ul--unstyled, &.ul--row {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 0;
    }
  }

  &.ul--row {
    li {
      display: inline-block;
    }
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li {
  font-size: $p-size;
  line-height: 1.5;
  font-weight: 300;
  color: inherit;
  padding-bottom: 15px;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

.body {
  position: relative;
  z-index: 2;

  .btn {
    margin: 30px 0;
  }
}

//=====================================
// #COLOURS/LINKS
//=====================================

.color--white {
  color: palette(white);

  > * {
    color: palette(white);
  }
}

.color--black {
  color: palette(black, dark);

  > * {
    color: palette(black, dark);
  }
}

.color--blue {
  color: palette(blue, dark);

  > * {
    color: palette(blue, dark);
  }
}

.color--pink {
  color: palette(pink);

  > * {
    color: palette(pink);
  }
}

.color--teal {
  color: palette(teal);

  > * {
    color: palette(teal);
  }
}

.short {
  margin-bottom: 0;
}

.o5 {
  opacity: .5;
}

.unibody-small-caps {
  font-family: unibody-8-small-caps-new, sans-serif;
  font-weight: 400;
  font-style: normal !important;
}

.unibody-reg {
  font-family: unibody-8-new, sans-serif !important;
  font-weight: 400 !important;
  text-transform: none !important;
  font-style: normal !important;
}

.unibody-bold {
  font-family: unibody-8-new, sans-serif !important;
  font-weight: 700;
  font-style: normal !important;
}

.body table {
  font-size: 1.8rem;
  line-height: 1.9;
  font-weight: 300;

  td {
    border: 1px solid black;
    padding: 5px;
  }
}
