//=====================================
// #COOKIE
//=====================================
#cookie-message {
  display: none;
  position: fixed;
  padding: 20px 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  background-color: palette(teal);
}

@include breakpoint(medium) {
  #cookie-message {
    padding: 20px 0;

    p {
      font-size: 1.8rem;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}