$palettes: (
        white: (
                base: #ffffff
        ),
        black: (
                base: #333333,
                dark: #000000
        ),
        grey: (
                light: #eaeaea,
                base: #5f5f5f,
                dark: #3a3a3a
        ),
        pink: (
                base: #FF02B4
        ),
        teal: (
                base: #00FFCA
        ),
        green: (
                success: #32b292
        ),
        red: (
                error: #EE3D52
        ),
        blue: (
                base: #230E47,
                dark: #021129
        )
) !global;

// ==========================================================================
// COLOR PALETTE FUNCTION
// ==========================================================================
// Example:
//
// a {
//     color: palette(yellow, light);
// }
//

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}