// ==========================================================================
// BACKGROUND COVER MIXIN
// ==========================================================================

@mixin bg-cover() {
    background-size: 100%;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
}

.bg-cover {
    @include bg-cover();
}

.no-bg-sm {
    @include breakpoint-max("medium") {
        background-image: none !important;
    }
}
